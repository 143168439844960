import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import { t } from '../lib/translate';
import Neono from './Neono';
import { useLang } from "../contexts/LangContext";


async function fetchSrc() {
  const src = require.context('../assets/md/ru', false, /\.md$/);
  const fetchPromises = src.keys().map(filename =>
    fetch(src(filename)).then((response) => response.text())
  );

  return Promise.all(fetchPromises).then(async (contents) => {
    let md = [];
    await contents.map(async (content, index) => {
      const regex = /^# (.+)/; // Matches lines starting with # followed by one or more characters
      const title = content.match(regex)?.[1]; // Extract the captured content after the #
      const body = content.replace(regex, ''); // Remove the first line from the content
      md.push({
        filename: src.keys()[index],
        body,
        title,
      })
    });

    return md;
  });
}

const fetchAll = async () => {
  const a = [];
  a.push(await fetchSrc());
  return a;
}


export default function Book() {

  const { lang, toc, setToc } = useLang();
  const [n, setN] = useState(0);
  const [all, setAll] = useState(false);
  const [mdf, setMdf] = useState([]);
  const [loading, setLoading] = useState(false);
  const [contracted, setContracted] = useState([]);

  const handleContract = (index) => () => {
    contracted.includes(index) ?
      setContracted(prev => prev.filter((item) => item !== index))
      : setContracted(prev => [...prev, index]);
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const a = await fetchAll();
      setAll(a);

      const toc = [];
      a[n].forEach(file => {
        toc.push({
          link: `#${file.filename.replace(/\.\//gm, '')}`,
          title: file.title,
          body: file.body,
        });
      });
      setMdf(a[n]);
      setToc(toc);
      setLoading(false);
    })();

  }, [n]);

  return (
    <>
      {loading && <Neono />}

      <div className="space">

        <div className="toc">
          <a name="top" id="top">&nbsp;</a>
          <h3 className="toc__title">{t[lang].contents}</h3>
          <ul>
            {toc?.map((item, index) => (
              <li key={index}>
                <a href={item.link}>{item.title}</a>
              </li>
            ))}
          </ul>
        </div>

        <div className="book__content">
          {mdf?.map((file, index) => (
            <div className="chapter" key={index}>
              <a
                className="chunk-anchor"
                onClick={
                  () => {
                    const element = document.getElementById(file.filename.replace(/\.\//gm, ''));
                    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                  }}
                id={file.filename.replace(/\.\//gm, '')}
                name={file.filename.replace(/\.\//gm, '')}
                href={`#${file.filename.replace(/\.\//gm, '')}`}>&nbsp;
              </a>
              
              <h1>
                <span className="contract-button" onClick={handleContract(index)}>
                  <span>{contracted.includes(index) ? '+' : '-'}</span>
                </span>
                 {file.title}
              </h1>
              <div className={`${contracted.includes(index) ? ' hide' : ' show'}`}>
                <ReactMarkdown>{file.body}</ReactMarkdown>

                <a
                className="chunk-anchor"
                onClick={
                  () => {
                    const element = document.getElementById('top');
                    element.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
                  }}
                href="#top">↑</a>              

              </div>
              
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
