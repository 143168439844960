import React from "react";
import { Routes, Route } from "react-router-dom";
import Book from "./components/Book";
import Front from "./components/Front";
import NotFound from "./components/NotFound";

export default function App() {  
 
  return (
    <>
      <div className={`app`}>
        <Routes>
          <Route path="*" element={<NotFound />} />
          <Route path="/book2" element={<Book />} />
        </Routes>
      </div>
    </>
  );
}


